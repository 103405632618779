import React, { useEffect, useState, useRef } from "react";
import logo from "../assets/logo.png";
import { FiChevronDown } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiCloseLargeLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Navbar = ({ show, setShow }) => {
  const [partnersDropdown, setPartnersDropdown] = useState(false);
  const [talentDropdown, setTalentDropdown] = useState(false);
  const [blogDropdown, setBlogDropdown] = useState(false);

  const partnersRef = useRef();
  const talentRef = useRef();
  const blogRef = useRef();

  const togglePartnersDropdown = () => {
    setPartnersDropdown(!partnersDropdown);
  };

  const toggleTalentDropdown = () => {
    setTalentDropdown(!talentDropdown);
  };

  const toggleBlogDropdown = () => {
    setBlogDropdown(!blogDropdown);
  };

  const handleClickOutside = (event) => {
    if (partnersRef.current && !partnersRef.current.contains(event.target)) {
      setPartnersDropdown(false);
    }
    if (talentRef.current && !talentRef.current.contains(event.target)) {
      setTalentDropdown(false);
    }

    if (blogRef.current && !blogRef.current.contains(event.target)) {
      setBlogDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShow(show);

    if (show) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [show]);

  const getAJob = () => {
    window.open("https://app.rivala.io/auth/signin", "_blank");
  };

  const hireTalent = () => {
    window.open("https://app.rivala.io/partners?step=1", "_blank");
  };

  const blog = () => {
    toggleBlogDropdown();
    window.open("https://rivalahq.medium.com/", "_blank");
  };

  const blogClick = () => {
    setShow(!show);
    window.open("https://rivalahq.medium.com/", "_blank");
  };
  return (
    <div className="px-[3%] lg:px-[5%] xl:px-[7%]  lg:border-b-[1px] border-gray-200">
      <div className="max-w-[1480px] mx-auto h-[80px] lg:flex justify-between items-center hidden ">
        <div className="flex items-center space-x-[20px]">
          <NavLink to="/">
            <div>
              <img className="w-[117px]" src={logo} />
            </div>
          </NavLink>

          <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/partner"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4 className="text-[14px] font-[600] p-[10px] hover:bg-gray-100 rounded-[8px]">
                How it works
              </h4>
            </NavLink>
          </div>
          <div
            className="relative flex items-center space-x-1 p-[10px] hover:bg-gray-100 rounded-[8px]"
            ref={partnersRef}
          >
            <button
              className="text-sm font-semibold"
              onClick={togglePartnersDropdown}
            >
              Partners
            </button>
            <FiChevronDown
              className={`text-lg transform transition-transform duration-200 ${
                partnersDropdown ? "rotate-180" : ""
              }`}
              onClick={togglePartnersDropdown}
            />
            {partnersDropdown && (
              <div className="absolute top-[30px] transform -translate-x-1/2 left-1/2">
                <div className="mt-[20px] w-[596px] bg-white border border-gray-200 rounded-[16px] shadow-lg p-[24px]">
                  <h4 className="text-[16px] font-[600] text-[#9EA0A3] pb-[16px] border-b-[1px] border-[#E6E7E8] uppercase mb-[20px]">
                    Partners
                  </h4>
                  <div className="grid grid-cols-2 gap-[18px]">
                    <div className=" hover:bg-gray-100 rounded-[8px] p-[8px]">
                      <NavLink
                        onClick={togglePartnersDropdown}
                        to="/why-rivala"
                      >
                        <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                          Why Rivala
                        </h4>
                        <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                          Scale Your Team with Our AI-Powered Talent Solution
                        </h4>
                      </NavLink>
                    </div>
                    <div className=" hover:bg-gray-100 rounded-[8px] p-[8px]">
                      <NavLink onClick={togglePartnersDropdown} to="/partner">
                        <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                          How it works
                        </h4>
                        <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                          Maximise our service with these steps.
                        </h4>
                      </NavLink>
                    </div>
                    <div className=" hover:bg-gray-100 rounded-[8px] p-[8px]">
                      <NavLink onClick={togglePartnersDropdown} to="/pricing">
                        <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                          Pricing
                        </h4>
                        <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                          Find your plan and unlock our benefits.
                        </h4>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative flex items-center space-x-1 p-[10px] hover:bg-gray-100 rounded-[8px]"
            ref={talentRef}
          >
            <button
              className="text-sm font-semibold"
              onClick={toggleTalentDropdown}
            >
              Talent
            </button>
            <FiChevronDown
              className={`text-lg transform transition-transform duration-200 ${
                talentDropdown ? "rotate-180" : ""
              }`}
              onClick={toggleTalentDropdown}
            />
            {talentDropdown && (
              <div className="absolute top-[30px] transform -translate-x-1/2 left-1/2">
                <div className="mt-[20px] w-[596px] bg-white border border-gray-200 rounded-[16px] shadow-lg p-[24px]">
                  <h4 className="text-[16px] font-[600] text-[#9EA0A3] pb-[16px] border-b-[1px] border-[#E6E7E8] uppercase mb-[20px]">
                    Talents
                  </h4>
                  <div className="grid grid-cols-2 gap-[18px]">
                    <div className=" hover:bg-gray-100 rounded-[8px] p-[8px]">
                      <NavLink onClick={toggleTalentDropdown} to="/jobs">
                        <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                          Jobs
                        </h4>
                        <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                          Explore jobs and join our supportive community.
                        </h4>
                      </NavLink>
                    </div>
                    <div className=" hover:bg-gray-100 rounded-[8px] p-[8px]">
                      <NavLink onClick={toggleTalentDropdown} to="/talent">
                        <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                          Why Rivala
                        </h4>
                        <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                          Unlock Your Potential and Connect Globally from
                          Anywhere
                        </h4>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative flex items-center space-x-1 p-[10px] hover:bg-gray-100 rounded-[8px]"
            ref={blogRef}
          >
            <button
              className="text-sm font-semibold"
              onClick={toggleBlogDropdown}
            >
              Resources
            </button>
            <FiChevronDown
              className={`text-lg transform transition-transform duration-200 ${
                blogDropdown ? "rotate-180" : ""
              }`}
              onClick={toggleBlogDropdown}
            />
            {blogDropdown && (
              <div className="absolute top-[30px] transform -translate-x-1/2 left-1/2">
                <div className="mt-[20px] w-[300px] bg-white border border-gray-200 rounded-[16px] shadow-lg p-[24px]">
                  <h4 className="text-[16px] font-[600] text-[#9EA0A3] pb-[16px] border-b-[1px] border-[#E6E7E8] uppercase mb-[20px]">
                    Resources
                  </h4>
                  <div className="grid grid-cols-1 gap-[18px]">
                    <div
                      onClick={blog}
                      className=" hover:bg-gray-100 rounded-[8px] p-[8px] cursor-pointer "
                    >
                      <h4 className="text-text font-[600] text-[14px] leading-[20px]">
                        Blog
                      </h4>
                      <h4 className="text-[14px] font-[400] leading-[20px] text-gray-400 mt-[10px]">
                        Insights, Strategies, and Tips for Successful Hiring
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/careers"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4 className="text-[14px] font-[600] ">careers</h4>
            </NavLink>
            <FiChevronDown className="text-[18px]" />
          </div> */}
        </div>
        <div className="flex items-center space-x-[24px]">
          <NavLink
            to="/contact"
            style={({ isActive }) => {
              return { color: isActive ? "#eab308" : "#101828" };
            }}
          >
            <button className="text-[14px] font-[600] p-[10px] hover:bg-gray-100 rounded-[8px]">
              Contact us
            </button>
          </NavLink>
          <button
            onClick={getAJob}
            className="hover:bg-gray-100 px-[18px] py-[10px] border-[1px] border-[#E6E7E8] rounded-[8px] text-[14px] font-[500] text-[#101828]"
          >
            Get a job
          </button>
          <button
            onClick={hireTalent}
            className="hover:bg-gray-700 px-[18px] py-[10px] rounded-[8px] text-[14px] font-[500] text-white bg-[#04040A]"
          >
            Hire talent
          </button>
        </div>
      </div>
      <div className="bg-white lg:py-[16px] py-[20px] flex justify-between items-center lg:hidden">
        <NavLink to="/">
          <div>
            <img className="w-[117px]" src={logo} />
          </div>
        </NavLink>

        <div>
          {show ? (
            <RiCloseLargeLine
              onClick={() => setShow(!show)}
              className="text-[22px]"
            />
          ) : (
            <RxHamburgerMenu
              onClick={() => setShow(!show)}
              className="text-[22px]"
            />
          )}
        </div>
      </div>
      <div
        className={`top-0 left-0 z-40 absolute bg-white w-full  overflow-y-auto h-[calc(100vh-60px)] lg:hidden py-[40px] ${
          show ? "translate-y-[60px]" : "-translate-y-[1300px]"
        } ease-in-out duration-500`}
      >
        <div className="flex items-center flex-col space-y-[40px]">
          <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/partner"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4
                onClick={() => setShow(!show)}
                className="text-[14px] font-[600] "
              >
                How it works
              </h4>
            </NavLink>
            {/* <FiChevronDown className="text-[18px]" /> */}
          </div>
          <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/pricing"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4
                onClick={() => setShow(!show)}
                className="text-[14px] font-[600] "
              >
                Pricing
              </h4>
            </NavLink>
            {/* <FiChevronDown className="text-[18px]" /> */}
          </div>
          <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/jobs"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4
                onClick={() => setShow(!show)}
                className="text-[14px] font-[600] "
              >
                Career
              </h4>
            </NavLink>
            {/* <FiChevronDown className="text-[18px]" /> */}
          </div>
          <div className="flex items-center space-x-[6px]">
            <NavLink
              to="/why-rivala"
              style={({ isActive }) => {
                return { color: isActive ? "#eab308" : "#101828" };
              }}
            >
              <h4
                onClick={() => setShow(!show)}
                className="text-[14px] font-[600] "
              >
                Why Rivala
              </h4>
            </NavLink>
            {/* <FiChevronDown className="text-[18px]" /> */}
          </div>
          <NavLink
            to="/contact"
            style={({ isActive }) => {
              return { color: isActive ? "#eab308" : "#101828" };
            }}
          >
            <h4
              onClick={() => setShow(!show)}
              className="text-[14px] font-[600] "
            >
              Contact us
            </h4>
          </NavLink>
          <h4
            onClick={blogClick}
            className="text-[14px] font-[600] cursor-pointer"
          >
            Resources
          </h4>
          <button
            onClick={() => {
              setShow(!show);
              getAJob();
            }}
            className="hover:bg-gray-100 px-[18px] py-[10px] border-[1px] border-[#E6E7E8] rounded-[8px] text-[14px] font-[500] text-[#101828]"
          >
            Get a job
          </button>
          <button
            onClick={() => {
              setShow(!show);
              hireTalent();
            }}
            className="hover:bg-gray-700 px-[18px] py-[10px] rounded-[8px] text-[14px] font-[500] text-white bg-[#04040A]"
          >
            Hire talent
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
